<template>
    <section class="col-12">
        <div class="row mx-0">
            <p v-if="!tabs" class="col text-general f-17 f-500 mb-3">
                Entregas vs Dinero recibido en el último mes
            </p>
            <p v-else class="col text-general f-17 f-500 mb-3">
                Entregas vs {{ tab === 1 ? 'peso' : tab === 2 ? 'peso volumetrico' : tab === 3 ? 'distancia' : 'calificación' }} en el último mes
            </p>
            <div v-show="tabs" class="col-auto">
                <div class="d-flex br-8 buttons-grap">
                    <div :class="`cr-pointer  d-middle-center br-l-8 ${tab === 1 ? 'bg-general3 text-white' : 'bg-fondo text-general'}`" @click="tab = 1">
                        <el-tooltip content="Ver po peso" placement="bottom" effect="light">
                            <i class="icon-scale f-20" />
                        </el-tooltip>
                    </div>
                    <div :class="`cr-pointer border-left border-right d-middle-center ${tab === 2 ? 'bg-general3 text-white' : 'bg-fondo text-general'}`" @click="tab = 2">
                        <el-tooltip content="Ver por volumen" placement="bottom" effect="light">
                            <i class="icon-axis-arrow f-20" />
                        </el-tooltip>
                    </div>
                    <div :class="`cr-pointer  border-right d-middle-center ${tab === 3 ? 'bg-general3 text-white' : 'bg-fondo text-general'}`" @click="tab = 3">
                        <el-tooltip content="ver por distancia" placement="bottom" effect="light">
                            <i class="icon-route f-20" />
                        </el-tooltip>
                    </div>
                    <div :class="`cr-pointer d-middle-center br-r-8 ${tab === 4 ? 'bg-general3 text-white' : 'bg-fondo text-general'}`" @click="tab = 4">
                        <el-tooltip content="ver por calificación" placement="bottom" effect="light">
                            <i class="icon-star f-20" />
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="col-12 px-0">
                <chartBarras :datos="datos_generales" :chart="chart_diario" />
            </div>
        </div>
    </section>
</template>

<script>
import Entregas from "~/services/delivery/index";
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')

export default {
    props:{
        tabs:{
            type:Boolean,
            default:false
        }
    },
    data(){
        let that = this
        return{
            tab:0,
            datos_generales:[],
            chart_diario: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    //data:['Entregas', 'Dinero']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['dia', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: "Dinero recibido",
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral
                        },
                    },
                    {
                        name: 'Entregas',
                        type: 'bar',
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            },
            dataGraficas:{}

        }
    },

    watch:{
        tab(val){
            this.setGrafica()
        }
    },
    methods:{
        async initGraficaDinero(idDelivery){
            try {

                const {data} = await Entregas.getGraficaEntregaVsDinero(idDelivery)
                this.tab = 0
                this.chart_diario.legend.data = ['Entregas', 'Dinero recibido']
                this.chart_diario.series[0].name = "Dinero recibido"
                this.chart_diario.dataset.source = data.data_grafica
                this.datos_generales = []

                this.datos_generales.push(
                    {
                        nombre:"Entregas",
                        valor:data.total_cantidad,
                        formato:2
                    },
                    {
                        nombre:"Dinero recibido",
                        valor:data.total_valor,
                        formato:1
                    }
                )
            } catch (error){
                this.error_catch(error)
            }
        },
        async initGraficaPesos(idDelivery){
            try {

                const {data} = await Entregas.getGraficaEntregaVsPesosAndDistancias(idDelivery)
                this.tab = 1
                this.dataGraficas = data
                this.datos_generales = []

                this.datos_generales.push(
                    {
                        nombre:"Entregas",
                        valor:data.total_entregas,
                        formato:2
                    }
                )
                this.setGrafica()


            } catch (error){
                this.error_catch(error)
            }
        },
        setGrafica(){
            let grafica = {}
            if(this.tab === 1){
                this.chart_diario.legend.data = ['Entregas', 'Peso']
                grafica = this.dataGraficas.grafica_pesos
            }
            if(this.tab === 2){
                this.chart_diario.legend.data = ['Entregas', 'Peso Volumetrico']
                grafica = this.dataGraficas.grafica_volumen
            }
            if(this.tab === 3){
                this.chart_diario.legend.data = ['Entregas', 'Distancia recorrida']
                grafica = this.dataGraficas.grafica_distancia
            }
            if(this.tab === 4){
                this.chart_diario.legend.data = ['Entregas', 'Calificaciones']
                grafica = this.dataGraficas.grafica_calificaciones
            }
            this.chart_diario.series[0].name = this.tab === 0 ? "Dinero recibido" : this.tab === 1 ? "Peso" : this.tab === 2 ? "Peso Volumetrico" : this.tab === 3 ? "Distancia recorrida" : "Calificaciones",

            this.chart_diario.dataset.source = grafica.grafica

            this.datos_generales[1] = {
                nombre:this.tab===1 ? "Peso Total (Kg)" : this.tab === 2 ? "Volumen Total (m3)" : this.tab === 3 ? "Distancia Recorrida (Km)" : "Calificaciones",
                valor:grafica.total,
                formato:2
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.buttons-grap{
    border: 1px solid #CFD7DF;
    div{
        width: 32px;
        height: 32px;
    }
}
</style>
